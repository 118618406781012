import Base from "../../components/Base";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Container,
    TextField,
    Typography
} from "@mui/material";
import {Styled1Paper} from "../Top/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as React from "react";
import {StyledButtonSubmit} from "../Login/styles";
import {useState} from "react";
import {useRecoilState} from "recoil";
import {AuthState} from "../../api/Recoil";
import {
    EmailAuthProvider,
    getAuth,
    GoogleAuthProvider,
    linkWithCredential,
    linkWithPopup,
    onAuthStateChanged,
    updateEmail,
    updatePassword,
    unlink
} from "firebase/auth";

export default function Setting() {
    const [authState, setAuthState] = useRecoilState(AuthState)
    const googleProvider = new GoogleAuthProvider();
    const [mail, setMail] = useState("");
    const [password, setPassword] = useState("");


    const linkGoogle = () => {
        const auth = getAuth();
        if (auth.currentUser) {
            linkWithPopup(auth.currentUser, googleProvider).then((result) => {
                onAuthStateChanged(auth, (authUser) => {
                    if (authUser)
                        console.log(authUser)
                    setAuthState(authUser);
                })
                alert("Account linking success !!")
                window.location.reload();
            }).catch((error) => {
                alert(error)
            });
        }
    }

    const unlinkGoogle = () => {
        const auth = getAuth();
        if (auth.currentUser) {
            const providerId = authState?.providerData.find(d => d.providerId === "google.com")?.providerId
            if (providerId == null) {
                alert("googleとの連携がされていません")
                return
            }
            unlink(auth.currentUser, providerId).then((result) => {
                alert("unlinked!")
                window.location.reload();
            }).catch((error) => {
                alert(error)
            });
        }
    }

    const linkMail = () => {
        const auth = getAuth();
        if (auth.currentUser) {
            console.log(mail, password)
            const credential = EmailAuthProvider.credential(mail, password);
            linkWithCredential(auth.currentUser, credential).then((result) => {
                const user = result.user;
                console.log("Account linking success", user);
                alert("Account linking success !!")
                window.location.reload();
            }).catch((error) => {
                console.log(error);
                console.log("Account linking error", error);
                alert("Account linking error")
            });
        }
    }

    const unlinkMail = () => {
        const auth = getAuth();
        if (auth.currentUser) {
            const providerId = authState?.providerData.find(d => d.providerId === "password")?.providerId
            if (providerId == null) {
                alert("メールアドレスとパスワードによる連携がされていません")
                return
            }
            unlink(auth.currentUser, providerId).then((result) => {
                alert("unlinked!")
                window.location.reload();
            }).catch((error) => {
                alert(error)
            });
        }
    }

    const updateUserEmail = () => {
        const auth = getAuth();
        if (auth.currentUser) {
            updateEmail(auth.currentUser, mail).then(() => {
                alert("updated E-Mail!")
                window.location.reload();
            }).catch((error) => {
                alert(error)
            });
        }
    }

    const updateUserPassword = () => {
        const auth = getAuth();
        if (auth.currentUser) {
            updatePassword(auth.currentUser, password).then(() => {
                alert("updated Password!")
                window.location.reload();
            }).catch((error) => {
                alert(error)
            });
        }
    }

    return (
        <Base>
            <Container component="main">
                <Styled1Paper>
                    <h1>設定</h1>
                    <Box sx={{width: 600}}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>アカウント連携</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    authState?.providerData.find(d => d.providerId === "google.com") == null &&
                                  <StyledButtonSubmit
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={linkGoogle}
                                  >
                                    Link Google
                                  </StyledButtonSubmit>
                                }
                                {
                                    (authState?.providerData.length ?? 0) > 1 &&
                                    authState?.providerData.find(d => d.providerId === "google.com") != null &&
                                  <StyledButtonSubmit
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={unlinkGoogle}
                                  >
                                    Unlink Google
                                  </StyledButtonSubmit>
                                }
                            </AccordionDetails>
                        </Accordion>
                        <br/>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>メールアドレスの登録/変更</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {
                                    authState?.providerData.find(d => d.providerId === "password") == null &&
                                  <div>
                                    <TextField
                                      variant="outlined"
                                      margin="normal"
                                      required
                                      fullWidth
                                      id="email"
                                      label="E-Mail"
                                      name="email"
                                      autoComplete="email"
                                      autoFocus
                                      defaultValue=""
                                      onChange={event => setMail(event.target.value)}
                                    />
                                    <TextField
                                      variant="outlined"
                                      margin="normal"
                                      required
                                      fullWidth
                                      name="password"
                                      label="Password"
                                      type="password"
                                      id="password"
                                      autoComplete="current-password"
                                      defaultValue=""
                                      onChange={event => setPassword(event.target.value)}
                                    />
                                    <StyledButtonSubmit
                                      fullWidth
                                      variant="contained"
                                      color="primary"
                                      onClick={linkMail}
                                    >
                                      Link E-Mail
                                    </StyledButtonSubmit>
                                  </div>
                                }
                                {
                                    (authState?.providerData.length ?? 0) > 1 &&
                                    authState?.providerData.find(d => d.providerId === "google.com") != null &&
                                  <div>
                                    <StyledButtonSubmit
                                      fullWidth
                                      variant="contained"
                                      color="primary"
                                      onClick={unlinkMail}
                                    >
                                      Unlink Mail
                                    </StyledButtonSubmit>
                                    <TextField
                                      variant="outlined"
                                      margin="normal"
                                      required
                                      fullWidth
                                      id="email"
                                      label="E-Mail"
                                      name="email"
                                      autoComplete="email"
                                      autoFocus
                                      defaultValue=""
                                      onChange={event => setMail(event.target.value)}
                                    />
                                    <StyledButtonSubmit
                                      fullWidth
                                      variant="contained"
                                      color="primary"
                                      onClick={updateUserEmail}
                                    >
                                      Update E-Mail
                                    </StyledButtonSubmit>
                                    <TextField
                                      variant="outlined"
                                      margin="normal"
                                      required
                                      fullWidth
                                      name="password"
                                      label="Password"
                                      type="password"
                                      id="password"
                                      autoComplete="current-password"
                                      defaultValue=""
                                      onChange={event => setPassword(event.target.value)}
                                    />
                                    <StyledButtonSubmit
                                      fullWidth
                                      variant="contained"
                                      color="primary"
                                      onClick={updateUserPassword}
                                    >
                                      Update Password
                                    </StyledButtonSubmit>
                                  </div>
                                }
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Styled1Paper>
            </Container>

            {/*<Elements stripe={stripePromise}>*/}
            {/*    <PaymentElement/>*/}
            {/*</Elements>*/}
            {/*<CardCVCElement/>*/}
            {/*<form onSubmit={handleSubmit}>*/}
            {/*    <PaymentElement/>*/}
            {/*    <button disabled={!stripe}>Submit</button>*/}
            {/*</form>*/}
        </Base>
    );
}
