import * as React from 'react';
import Button from '@mui/material/Button';
import Base from "../../components/Base";
import {Styled1Paper} from './styles';
import {Box, Card, CardActions, CardContent, CardHeader, Container, Typography} from "@mui/material";
import {useNavigate, useParams} from 'react-router-dom';
import {getFunctions, httpsCallable} from "firebase/functions";
import {useRecoilValue} from "recoil";
import {AuthState} from "../../api/Recoil";
import {useEffect} from "react";
import {restfulApiConfig} from "../../Config";

export default function Payment() {
    let amount: string | undefined;
    ({amount} = useParams());
    const amountNum = Number(amount)
    const navigate = useNavigate();
    const authState = useRecoilValue(AuthState)

    useEffect(() => {
        if (Number.isNaN(amountNum)) {
            navigate("/")
        }
        if (amountNum < 100) {
            navigate("/")
        }
    }, [])

    const payment = async () => {
        const functions = getFunctions();
        const createPaymentSession = httpsCallable(functions, "createPaymentSession");

        createPaymentSession({
            price: Number(amount),
            name: authState?.displayName,
            uid: authState?.uid,
            return_url: restfulApiConfig.main.url
        }).then((result: any) => {
            window.location.replace(result.data)
        })
    };

    return (
        <Base>
            <Container component="main">
                <Styled1Paper>
                    <h1>寄付 お支払いページ</h1>
                    <Card>
                        <CardHeader
                            title=""
                            titleTypographyProps={{align: 'center'}}
                            subheaderTypographyProps={{
                                align: 'center',
                            }}
                            sx={{
                                backgroundColor: (theme) =>
                                    theme.palette.mode === 'light'
                                        ? theme.palette.grey[200]
                                        : theme.palette.grey[700],
                            }}
                        />
                        <CardContent>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'baseline',
                                    mb: 2,
                                }}
                            >
                                <Typography component="h2" variant="h3" color="text.primary">
                                    {amount} 円
                                </Typography>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Button
                                fullWidth
                                variant={"outlined"}
                                sx={{maxWidth: 300}}
                                onClick={() => payment()}
                            >
                                お支払い
                            </Button>
                        </CardActions>
                    </Card>
                    <br/>
                </Styled1Paper>
            </Container>
        </Base>
    );
}
