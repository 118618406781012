import React, {ReactNode,} from "react";
import {Navigate, Route} from "react-router-dom";
import {useRecoilState, useRecoilValue} from "recoil";
import {AuthState} from "../api/Recoil";

export const PrivateRoute: React.VFC<{
    children: ReactNode;
}> = (props) => {
    const {children} = props;
    console.log(useRecoilValue(AuthState))
    return useRecoilValue(AuthState) ? (<>{children}</>) : (<Navigate to="/"/>);
};

