export const basedata = {
    "subscribe": [
        {
            "title": "",
            "price": "500",
            "priceId": "price_1LWddLKAsFsFzn5nfQhfiAek",
            "buttonText": "Sign up for free",
            "buttonVariant": "outlined"
        },
        {
            "title": "",
            "price": "1000",
            "priceId": "price_1LTZD3KAsFsFzn5n9m9kJAAt",
            "buttonText": "Get started",
            "buttonVariant": "contained"
        },
        {
            "title": "",
            "price": "3000",
            "priceId": "price_1LTZD3KAsFsFzn5nKFisfr0q",
            "buttonText": "Contact us",
            "buttonVariant": "outlined"
        },
        {
            "title": "",
            "price": "5000",
            "priceId": "price_1LWde4KAsFsFzn5nSgbBwe6C",
            "buttonText": "Contact us",
            "buttonVariant": "outlined"
        },
        {
            "title": "",
            "price": "10000",
            "priceId": "price_1LWdh0KAsFsFzn5naJhlBtB1",
            "buttonText": "Contact us",
            "buttonVariant": "outlined"
        },
        {
            "title": "",
            "price": "30000",
            "priceId": "price_1LWdhKKAsFsFzn5n20v1tZ4v",
            "buttonText": "Contact us",
            "buttonVariant": "outlined"
        }
    ]
}
