import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {RecoilRoot} from "recoil";
import {restfulApiConfig} from './Config';
import {getAuth} from 'firebase/auth'
import {initializeApp} from 'firebase/app'

export const firebaseConfig = {
    apiKey: restfulApiConfig.firebase.apiKey,
    authDomain: restfulApiConfig.firebase.authDomain,
    projectId: restfulApiConfig.firebase.projectId,
    storageBucket: restfulApiConfig.firebase.storageBucket,
    messagingSenderId: restfulApiConfig.firebase.messagingSenderId,
    appId: restfulApiConfig.firebase.appId,
    measurementId: restfulApiConfig.firebase.measurementId
}
export const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth()


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RecoilRoot>
            <App/>
        </RecoilRoot>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
