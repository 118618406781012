import {
    Container,
    CssBaseline,
    Grid,
    Link,
    TextField, Typography
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React, {FormEvent, useState} from 'react';
import {StyledAvatar, StyledButtonSubmit, StyledForm, StyledPaper} from './styles';
import Base from "../../components/Base";
import {auth, firebaseConfig} from "../../index";
import {signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {AuthState} from "../../api/Recoil";
import {useSetRecoilState} from "recoil";

export default function SignIn() {
    const navigate = useNavigate();
    const setAuth = useSetRecoilState(AuthState);
    const [mail, setMail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (e: FormEvent) => {
        console.log(firebaseConfig)
        e.preventDefault();

        signInWithEmailAndPassword(auth, mail, password).then(res => {
            console.log('user created');
            console.log(res)
            if (res.user) {
                setAuth(res.user);
                navigate('/dashboard');
            }
        }).catch((error) => {
            alert(error.message)
            console.error(error)
        });
        console.log(mail);
    }

    const googleSignIn = () => {
        var provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                console.log(result)
                navigate("/dashboard")
            }).catch((error) => {
            alert(error)
        });
    }

    return (
        <Base>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <StyledPaper>
                    <StyledAvatar>
                        <LockOutlinedIcon/>
                    </StyledAvatar>
                    <Typography component="h1" variant="h5">
                        ログイン
                    </Typography>
                    <StyledForm noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-Mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            defaultValue=""
                            onChange={event => setMail(event.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            defaultValue=""
                            onChange={event => setPassword(event.target.value)}
                        />
                        <StyledButtonSubmit
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Sign In
                        </StyledButtonSubmit>
                        <StyledButtonSubmit
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={googleSignIn}
                        >
                            Sign In With Google
                        </StyledButtonSubmit>
                        <Grid container>
                            <Grid item xs>
                                {/*<Link href="/forget" variant="body2">*/}
                                {/*    Forgot password?*/}
                                {/*</Link>*/}
                            </Grid>
                            <Grid item>
                                <Link href="/register" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </StyledForm>
                </StyledPaper>
                {/*<Box mt={8}>*/}
                {/*    <Copyright/>*/}
                {/*</Box>*/}
            </Container>
        </Base>
    );
}
