import {
    Button,
    Container,
    CssBaseline,
    ThemeProvider, Typography
} from '@mui/material';
import React from 'react';
import Base from "../../components/Base";
import {getFunctions, httpsCallable} from "firebase/functions";
import {useRecoilValue} from "recoil";
import {AuthState} from "../../api/Recoil";
import {muiColorTheme} from "../../components/Theme";
import {Styled1Paper} from './styles';
import {restfulApiConfig} from "../../Config";
import {useNavigate} from "react-router-dom";

export default function MyPage() {
    const navigate = useNavigate();
    const authState = useRecoilValue(AuthState)

    const getPortal = async () => {
        const functions = getFunctions();
        const getBillingPortal = httpsCallable(functions, "getBillingPortal");

        getBillingPortal({
            uid: authState?.uid,
            return_url: restfulApiConfig.main.url
        }).then((result: any) => {
            if (result.error) {
                alert(result.error)
            } else {
                window.location.replace(result.data)
            }
        })
    };

    return (
        <ThemeProvider theme={muiColorTheme}>
            <Base>
                <Container component="main">
                    <Styled1Paper>
                        <h2>MyPage</h2>
                        <Typography variant="h6" align="center" color="text.secondary" component="p">
                            こちらで確認できる内容は毎月のご寄付に関する情報です。
                        </Typography>
                        <Typography variant="h6" align="center" color="text.secondary" component="p">
                            サブスクリプションを停止する場合や各種クレジットカード情報の変更はこちらからお願いします。
                        </Typography>
                        <Typography variant="h6" align="center" color="text.secondary" component="p">
                            (*ただし、アカウント登録時は本機能はご利用いただけません。毎月のご寄付を申し込み後に本機能をご利用いただけます。)
                        </Typography>
                        <Button variant="outlined" sx={{my: 1, mx: 1.5}} onClick={getPortal}>
                            毎月のご寄付やご解約
                        </Button>
                        <br/>
                        <br/>
                        <Typography variant="h6" align="center" color="text.secondary" component="p">
                            毎月のご寄付はこちらからお願いします。
                        </Typography>
                        <Button variant="outlined" sx={{my: 1, mx: 1.5}} onClick={() => navigate("/monthly")}>
                            毎月のご寄付はこちら
                        </Button>
                    </Styled1Paper>
                </Container>
                <CssBaseline/>
            </Base>
        </ThemeProvider>
    );
}
