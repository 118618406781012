import {
    Box,
    Button, Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Grid, ThemeProvider, Typography
} from '@mui/material';
import React from 'react';
import {Styled1Paper} from './styles';
import {muiColorTheme} from "../../components/Theme";
import Base from '../../components/Base';
import {useNavigate} from 'react-router-dom';
import {useRecoilValue} from "recoil";
import {AuthState} from "../../api/Recoil";

export default function Top() {
    const navigate = useNavigate();
    const authState = useRecoilValue(AuthState);
    const tiers = [
        {
            title: '',
            price: '500',
            buttonText: 'Sign up for free',
            buttonVariant: 'outlined',
        },
        {
            title: '',
            price: '1000',
            buttonText: 'Get started',
            buttonVariant: 'contained',
        },
        {
            title: '',
            price: '3000',
            buttonText: 'Contact us',
            buttonVariant: 'outlined',
        },
        {
            title: '',
            price: '5000',
            buttonText: 'Contact us',
            buttonVariant: 'outlined',
        },
        {
            title: '',
            price: '10000',
            buttonText: 'Contact us',
            buttonVariant: 'outlined',
        },
        {
            title: '',
            price: '50000',
            buttonText: 'Contact us',
            buttonVariant: 'outlined',
        },
    ];

    return (
        <ThemeProvider theme={muiColorTheme}>
            <Base>
                <Container component="main">
                    <Styled1Paper>
                        <h2>寄付のお願い</h2>
                        <Typography variant="h5" align="center" color="text.secondary" component="p">
                            当法人の活動にご理解とご賛同をいただける方にご寄付をお願いしております。
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" component="p">
                            詳細につきましては<a href={"https://www.homenoc.ad.jp/about/donation/"}>こちら</a>をご覧ください。
                        </Typography>
                        <Typography variant="h6" align="center" color="text.secondary" component="p">
                            皆さまからのご寄付は、適切に管理し、有効かつ大切に使わせていただきます。
                        </Typography>

                        <br/>

                        <Container maxWidth="md" component="main">
                            <Grid container spacing={5} alignItems="flex-end">
                                {tiers.map((tier) => (
                                    <Grid
                                        item
                                        key={tier.title}
                                        xs={12}
                                        sm={tier.title === 'Enterprise' ? 12 : 6}
                                        md={4}
                                    >
                                        <Card>
                                            <CardHeader
                                                title={tier.title}
                                                titleTypographyProps={{align: 'center'}}
                                                subheaderTypographyProps={{
                                                    align: 'center',
                                                }}
                                                sx={{
                                                    backgroundColor: (theme) =>
                                                        theme.palette.mode === 'light'
                                                            ? theme.palette.grey[200]
                                                            : theme.palette.grey[700],
                                                }}
                                            />
                                            <CardContent>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'baseline',
                                                        mb: 2,
                                                    }}
                                                >
                                                    <Typography component="h2" variant="h3" color="text.primary">
                                                        {tier.price} 円
                                                    </Typography>
                                                </Box>
                                            </CardContent>
                                            <CardActions>
                                                <Button
                                                    fullWidth
                                                    variant={tier.buttonVariant as 'outlined' | 'contained'}
                                                    onClick={() => navigate("/payment/" + tier.price)}
                                                >
                                                    寄付する
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Container>
                        <br/>
                        <br/>
                        {
                            authState == null &&
                          <Button variant="outlined" sx={{my: 1, mx: 1.5}} onClick={() => navigate("/register")}>
                            毎月のご支援はこちら(登録/ログインが必要です)
                          </Button>
                        }
                        {
                            authState != null &&
                          <Button variant="outlined" sx={{my: 1, mx: 1.5}} onClick={() => navigate("/monthly")}>
                            毎月のご支援はこちら
                          </Button>
                        }
                    </Styled1Paper>
                </Container>
            </Base>
        </ThemeProvider>
    );
}
