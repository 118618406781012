const Config = () => {
    if (process.env.REACT_APP_NODE_ENV === 'prod') {
        // production
        return {
            firebase: {
                apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY,
                authDomain: process.env.REACT_APP_PROD_FIREBASE_AUTH_DOMAIN,
                projectId: process.env.REACT_APP_PROD_FIREBASE_PROJECT_ID,
                storageBucket: process.env.REACT_APP_PROD_FIREBASE_STORAGE_BUCKET,
                messagingSenderId: process.env.REACT_APP_PROD_FIREBASE_MESSAGING_SENDER_ID,
                appId: process.env.REACT_APP_PROD_FIREBASE_APP_ID,
                measurementId: process.env.REACT_APP_PROD_FIREBASE_MEASUREMENT_ID
            },
            main: {
                url: process.env.REACT_APP_PROD_URL,
                identify: process.env.REACT_APP_PROD_IDENTIFY,
            }
        }
    } else {
        // develop
        return {
            firebase: {
                apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
                authDomain: process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN,
                projectId: process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID,
                storageBucket: process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET,
                messagingSenderId: process.env.REACT_APP_DEV_FIREBASE_MESSAGING_SENDER_ID,
                appId: process.env.REACT_APP_DEV_FIREBASE_APP_ID,
                measurementId: process.env.REACT_APP_DEV_FIREBASE_MEASUREMENT_ID
            },
            main: {
                url: process.env.REACT_APP_DEV_URL,
                identify: process.env.REACT_APP_DEV_IDENTIFY,
            }
        }
    }
}

export const restfulApiConfig = Config()
