import {
    Container,
    CssBaseline,
    Grid,
    Link,
    TextField, Typography
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React, {FormEvent, useState} from 'react';
import {StyledAvatar, StyledButtonSubmit, StyledForm, StyledPaper} from './styles';
import Base from "../../components/Base";
import {GoogleAuthProvider, createUserWithEmailAndPassword, signInWithPopup} from 'firebase/auth';
import {auth, firebaseConfig} from '../..';
import {useNavigate} from "react-router-dom";

export default function Register() {
    const navigate = useNavigate();
    const [mail, setMail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (e: FormEvent) => {
        console.log(firebaseConfig)
        e.preventDefault();

        createUserWithEmailAndPassword(auth, mail, password).then(res => {
            console.log('user created');
            console.log(res)
            navigate("/dashboard")
        }).catch((error) => {
            alert(error.message)
            console.error(error)
        });
        console.log(mail);
    }

    const googleSignIn = () => {
        var provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                console.log(result)
                navigate("/dashboard")
            }).catch((error) => {
            alert(error)
        });
    }

    return (
        <Base>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <StyledPaper>
                    <StyledAvatar>
                        <LockOutlinedIcon/>
                    </StyledAvatar>
                    <Typography component="h1" variant="h5">
                        新規登録
                    </Typography>
                    <br/>
                    <Typography component="p" variant="inherit">
                        (毎月のご寄付には、アカウント登録が必要です)
                    </Typography>
                    <StyledForm noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-Mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            defaultValue=""
                            onChange={event => setMail(event.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            defaultValue=""
                            onChange={event => setPassword(event.target.value)}
                        />
                        <StyledButtonSubmit
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Sign Up
                        </StyledButtonSubmit>
                        <StyledButtonSubmit
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={googleSignIn}
                        >
                            Google Authentication
                        </StyledButtonSubmit>
                        <Grid container>
                            <Grid item xs>
                                {/*<Link href="/forget" variant="body2">*/}
                                {/*    Forgot password?*/}
                                {/*</Link>*/}
                            </Grid>
                            <Grid item>
                                <Link href="/login" variant="body2">
                                    ログインはこちら
                                </Link>
                            </Grid>
                        </Grid>
                    </StyledForm>
                </StyledPaper>
                {/*<Box mt={8}>*/}
                {/*    <Copyright/>*/}
                {/*</Box>*/}
            </Container>
        </Base>
    );
}
