import {
    Box,
    Button, Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Grid,
    ThemeProvider, Typography
} from '@mui/material';
import StarIcon from '@mui/icons-material/StarBorder';
import React from 'react';
import {Styled1Paper} from './styles';
import {muiColorTheme} from "../../components/Theme";
import Base from '../../components/Base';
import {useNavigate} from 'react-router-dom';
import {getFunctions, httpsCallable} from "firebase/functions";
import {useRecoilValue} from "recoil";
import {AuthState} from "../../api/Recoil";
import {restfulApiConfig} from "../../Config";
import {basedata} from "../../api/data";

export default function Monthly() {
    const navigate = useNavigate();
    const authState = useRecoilValue(AuthState)

    const payment = async (plan: string) => {
        const functions = getFunctions();
        const createSubscribePaymentSession = httpsCallable(functions, "createSubscribePaymentSession");

        createSubscribePaymentSession({
            plan: plan,
            name: authState?.displayName,
            uid: authState?.uid,
            return_url: restfulApiConfig.main.url
        }).then((result: any) => {
            window.location.replace(result.data)
        })
    };

    return (
        <ThemeProvider theme={muiColorTheme}>
            <Base>
                <Container component="main">
                    <Styled1Paper>
                        <h2>寄付のお願い(毎月継続)</h2>
                        <Typography variant="h4" align="center" color="#f00" component="p">
                            このページは毎月ご支援いただける方を対象にしています。
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" component="p">
                            当法人の活動にご理解とご賛同をいただける方にご寄付をお願いしております。
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" component="p">
                            詳細につきましては<a href={"https://www.homenoc.ad.jp/about/donation/"}>こちら</a>をご覧ください。
                        </Typography>
                        <Typography variant="h6" align="center" color="text.secondary" component="p">
                            皆さまからのご寄付は、適切に管理し、有効かつ大切に使わせていただきます。
                        </Typography>
                        <Typography variant="h6" align="center" color="text.secondary" component="p">
                            ご寄付は複数可能です。現在の毎月のご寄付に関しては、<a href={"/dashboard"}>こちら</a>から確認できます。
                        </Typography>
                        <br/>
                        <Container maxWidth="md" component="main">
                            <Grid container spacing={5} alignItems="flex-end">
                                {basedata.subscribe.map((tier) => (
                                    <Grid
                                        item
                                        key={tier.title}
                                        xs={12}
                                        sm={tier.title === 'Enterprise' ? 12 : 6}
                                        md={6}
                                    >
                                        <Card>
                                            <CardHeader
                                                title={tier.title}
                                                titleTypographyProps={{align: 'center'}}
                                                action={tier.title === 'Pro' ? <StarIcon/> : null}
                                                subheaderTypographyProps={{
                                                    align: 'center',
                                                }}
                                                sx={{
                                                    backgroundColor: (theme) =>
                                                        theme.palette.mode === 'light'
                                                            ? theme.palette.grey[200]
                                                            : theme.palette.grey[700],
                                                }}
                                            />
                                            <CardContent>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'baseline',
                                                        mb: 2,
                                                    }}
                                                >
                                                    <Typography component="h2" variant="h3" color="text.primary">
                                                        {tier.price} 円 /月
                                                    </Typography>
                                                </Box>
                                            </CardContent>
                                            <CardActions>
                                                <Button
                                                    fullWidth
                                                    variant={tier.buttonVariant as 'outlined' | 'contained'}
                                                    onClick={() => payment(tier.priceId)}
                                                >
                                                    お支払い(毎月)
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Container>
                        <br/>
                        <br/>
                        <Button variant="outlined" sx={{my: 1, mx: 1.5}} onClick={() => navigate("/")}>
                            通常のご支援はこちら
                        </Button>
                    </Styled1Paper>
                </Container>
            </Base>
        </ThemeProvider>
    );
}
