import {
    Container,
    CssBaseline,
    Grid,
    Link,
    TextField, ThemeProvider, Typography
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React, {FormEvent, useState} from 'react';
import {StyledAvatar, StyledButtonSubmit, StyledForm, StyledPaper} from './styles';
import {muiColorTheme} from "../../components/Theme";
import Base from "../../components/Base";
import {auth, firebaseConfig} from "../../index";
import {sendPasswordResetEmail} from "firebase/auth";

export default function Forget() {
    const [email, setEmail] = useState("");

    const resetPassword = () => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                alert("パスワードの再設定メールを送信しました。")
            })
            .catch((error) => {
                alert(error)
            });
    }

    return (
        <Base>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <StyledPaper>
                    <StyledAvatar>
                        <LockOutlinedIcon/>
                    </StyledAvatar>
                    <Typography component="h1" variant="h5">
                        ログイン
                    </Typography>
                    <StyledForm noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-Mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            defaultValue=""
                            onChange={event => setEmail(event.target.value)}
                        />
                        <StyledButtonSubmit
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={resetPassword}
                        >
                            Sign In
                        </StyledButtonSubmit>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/login" variant="body2">
                                    Sign In
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/register" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </StyledForm>
                </StyledPaper>
                {/*<Box mt={8}>*/}
                {/*    <Copyright/>*/}
                {/*</Box>*/}
            </Container>
        </Base>
    );
}
