import {AppBar, Box, Button, CssBaseline, IconButton, Link, ThemeProvider, Toolbar, Typography} from "@mui/material";
import {muiColorTheme} from "./Theme";
import {useNavigate} from "react-router-dom";
import {useRecoilState, useRecoilValue} from "recoil";
import {AuthState} from "../api/Recoil";
import {getAuth, signOut} from "firebase/auth";

export default function Base(props: any) {
    const navigate = useNavigate();
    const [authState, setAuthState] = useRecoilState(AuthState)

    const logout = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            setAuthState(null)
        }).catch((error) => {
            alert(error)
        });

    }

    return (
        <ThemeProvider theme={muiColorTheme}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppBar position="fixed">
                    <Toolbar>
                        <Typography component="h1" variant="h6">
                            Donate System
                        </Typography>
                        <Box sx={{flexGrow: 1}}/>
                        <Button
                            variant="outlined"
                            sx={{my: 1, mx: 0.5}}
                            style={{
                                // borderRadius: 35,
                                color: "#fff",
                                borderColor: "#fff"
                                // padding: "18px 36px",
                                // fontSize: "18px"
                            }}
                            onClick={() => navigate("/")}>
                            Top
                        </Button>
                        {
                            authState == null &&
                          <Button
                            variant="outlined"
                            sx={{my: 1, mx: 0.5}}
                            style={{
                                // borderRadius: 35,
                                color: "#fff",
                                borderColor: "#fff"
                                // padding: "18px 36px",
                                // fontSize: "18px"
                            }}
                            onClick={() => navigate("/register")}>
                            Register
                          </Button>
                        }
                        {
                            authState == null &&
                          <Button
                            variant="outlined"
                            sx={{my: 1, mx: 0.5}}
                            style={{
                                color: "#fff",
                                borderColor: "#fff"
                            }}
                            onClick={() => navigate("/login")}
                          >
                            Login
                          </Button>
                        }
                        {
                            authState != null &&
                          <Button
                            variant="outlined"
                            sx={{my: 1, mx: 0.5}}
                            style={{
                                color: "#fff",
                                borderColor: "#fff"
                            }}
                            onClick={() => navigate("/dashboard")}
                          >
                            Dashboard
                          </Button>
                        }
                        {
                            authState != null &&
                          <Button
                            variant="outlined"
                            sx={{my: 1, mx: 0.5}}
                            style={{
                                color: "#fff",
                                borderColor: "#fff"
                            }}
                            onClick={() => navigate("/dashboard/setting")}
                          >
                            Setting
                          </Button>
                        }
                        {
                            authState != null &&
                          <Button
                            variant="outlined"
                            sx={{my: 1, mx: 0.5}}
                            style={{
                                color: "#fff",
                                borderColor: "#fff"
                            }}
                            onClick={logout}
                          >
                            Logout
                          </Button>
                        }
                    </Toolbar>
                </AppBar>
                {props.children}
            </Box>
        </ThemeProvider>
    )
}
