import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import SignIn from './pages/Login/SignIn';
import NotFound from './pages/Etc/404';
import Top from './pages/Top/Top';
import Monthly from './pages/Monthly/Monthly';
import Payment from "./pages/Top/payment";
import Register from './pages/Register/Register';
import MyPage from "./pages/MyPage/MyPage";
import {useSetRecoilState} from "recoil";
import {AuthState} from './api/Recoil';
import {onAuthStateChanged} from "firebase/auth";
import {auth} from '.';
import Setting from "./pages/Setting/Setting";
import {PrivateRoute} from './routes/PrivateRoute';
import Forget from './pages/Login/Forget';

function App() {
    const setAuth = useSetRecoilState(AuthState);
    const [isLoading, setIsLoading] = useState<Boolean>(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
            if (authUser) {
                setAuth(authUser);
            }
            setIsLoading(false);
        });
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <BrowserRouter>
            {isLoading ? (
                <p>Loading..</p>
            ) : (
                <Routes>
                    <Route path="*" element={<NotFound/>}/>
                    <Route path='/' element={<Top/>}/>
                    <Route path='/login' element={<SignIn/>}/>
                    <Route path='/forget' element={<Forget/>}/>
                    <Route path='/register' element={<Register/>}/>
                    <Route path='/payment/:amount' element={<Payment/>}/>
                    <Route path='/monthly' element={<PrivateRoute children={<Monthly/>}/>}/>
                    <Route path="/dashboard" element={<PrivateRoute children={<MyPage/>}/>}/>
                    <Route path="/dashboard/setting" element={<PrivateRoute children={<Setting/>}/>}/>
                </Routes>
            )}
        </BrowserRouter>
    );
}

export default App;
